<!--分销佣金提取页面-->
<template>
    <div>
        <div class="withdrawal-wrapper">
            <!--提现操作-->
            <div class="withdrawal-top-wrapper">
                <div class="withdrawal-money-wrapper">
                    <img src="https://seetop-1257860468.file.myqcloud.com/html/distributor/icon_records1.png">
                    <div>
                        <p>可提现金额（元）</p>
                    </div>
                </div>
                <div class="withdrawal-button-wrapper">
                    <p>{{computedSurplus}}</p>
                    <div @click="showDraw = true">
                        <p>提现</p>
                        <img src="https://seetop-1257860468.file.myqcloud.com/html/distributor/icon090202.png">
                    </div>
                </div>
            </div>
            <!--提现情况-->
            <div class="withdrawal-bottom-wrapper">
                <div class="withdrawal-money-text-wrapper">
                    <p>冻结金额（元）</p>
                    <p>{{computedFrozen}}</p>
                </div>
                <div class="withdrawal-money-text-wrapper">
                    <p>已提现金额（元）</p>
                    <p>{{computedExtract}}</p>
                </div>
            </div>
            <tab-switch v-bind:tab="['分销明细','提现明细']" active-item='分销明细' v-on:myClick="tab"></tab-switch>
        </div>
        <!--列表-->
        <div class="list-wrapper">
            <van-list
                    v-model="listLoading"
                    :finished="listLoadingFinished"
                    finished-text="没有更多了"
                    @load="onLoad"
            >
                <div v-show="currentTab == '分销明细'">
                    <div class="list-item-wrapper" v-for="(item, index) in listLoadingList" :key="index">
                        <div>
                            <div class="list-item-name-wrapper">
                                <p>{{item.weixinNick}}</p>
                                <p class="list-item-name-label" v-if="item.status == 0">冻结中</p>
                                <p class="list-item-name-label" style="background-color: #bbb;" v-if="item.status == -2">退款</p>
                            </div>
                            <p class="list-item-des">{{item.create_time}}成功推荐{{item.customer}}购买了{{item.name}}</p>
                        </div>
                        <div class="list-item-money">
                            <p>+<span>{{moneyFen2Yuan(item.money)}}元</span></p>
                        </div>
                    </div>
                </div>
                <div v-show="currentTab == '提现明细'">
                    <div class="list-item-wrapper" v-for="(item, index) in listLoadingList" :key="index">
                        <div>
                            <div class="list-item-name-wrapper">
                                <p>{{item.create_time}}</p>
                            </div>
                            <p>提现到微信钱包</p>
                        </div>
                        <div class="list-item-money">
                            <p>+<span>{{moneyFen2Yuan(item.money)}}元</span></p>
                        </div>
                    </div>
                </div>
            </van-list>
        </div>
        <van-overlay :show="showDraw" @click="showDraw = false">
            <div class="draw-wrapper" @click.stop>
                <img class="draw-close" @click="showDraw = false" src="https://seetop-1257860468.file.myqcloud.com/html/distributor/icon090201.png">
                <div class="draw-body-wrapper">
                    <div class="draw-input-wrapper">
                        <input placeholder="输入提现金额" type="tel" v-model="drawMoney">
                        <p @click="getAllSurplus()">全部提现</p>
                    </div>
                    <p class="draw-tip">提现到微信钱包（提现金额需整数）</p>
                    <p class="draw-button" @click="getCash()">立即提现</p>
                </div>
            </div>
        </van-overlay>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import TabSwitch from '../../components/common/TabSwitch'
  import { globalConfig, listLoading } from '../../utils/mixin'
  import { moneyFen2Yuan } from '../../utils/tools'
  import FloatNav from '../../components/common/FloatNav'
  export default {
    name: 'Money',
    components: { FloatNav, TabSwitch },
    mixins: [globalConfig, listLoading],
    data () {
      return {
        surplus: 0,
        frozen: 0,
        extract: 0,
        currentTab: '分销明细',
        showDraw: false,
        drawMoney: ''
      }
    },
    methods: {
      init: function () {
        this.axios.post(this.apiUrl + 'mall/distributor/bashInfo').then((response) => {
          const data = response.data
          if (data.status !== 1) {
            this.$router.push('/user')
          }
          this.surplus = data.info.surplus
          this.frozen = data.info.frozen
          this.extract = data.info.extract
        })
      },
      // 取钱
      getCash: function () {
        this.showDraw = false
        const money = Math.floor(this.drawMoney * 100)
        if (money >= 30) {} else {
          this.$toast('提取金额必须大于3毛')
          return
        }
        if (money <= this.surplus) {} else {
          this.$toast('余额不足')
          return
        }
        const that = this
        this.axios.post(this.apiUrl + 'mall/distributor/cash', {
          cash: money
        }).then((response) => {
          const data = response.data
          if (data.status === 1) {
            this.$toast({
              message: data.msg,
              onClose: function () {
                that.$router.push('/refreshRouter')
              }
            })
          } else {
            this.$toast(data.msg)
          }
        })
      },
      tab: function (name) {
        if (name === '分销明细') {
          this.listLoadingInit(this.apiUrl + 'mall/distributor/getDistributionLog', 'list', 1)
        } else if (name === '提现明细') {
          this.listLoadingInit(this.apiUrl + 'mall/distributor/cashLog', 'list', 1)
        }
        this.currentTab = name
      },
      moneyFen2Yuan: function (money) {
        return moneyFen2Yuan(money)
      },
      getAllSurplus: function () {
        this.drawMoney = this.surplus / 100
      }
    },
    computed: {
      computedSurplus: function () {
        return moneyFen2Yuan(this.surplus)
      },
      computedFrozen: function () {
        return moneyFen2Yuan(this.frozen)
      },
      computedExtract: function () {
        return moneyFen2Yuan(this.extract)
      }
    },
    created () {
      this.init()
    },
    mounted () {
      this.listLoadingInit(this.apiUrl + 'mall/distributor/getDistributionLog', 'list', 1)
    }
  }
</script>

<style scoped>
    .withdrawal-wrapper{
        background-color: #FFFFff;
        position: fixed;
        top:0
    }
    .withdrawal-top-wrapper{
        width:100vw;
        height: 50vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/distributor/佣金明细背景v2.png") no-repeat;
        background-size: 100% ;
    }
    .withdrawal-money-wrapper{
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFff;
        padding:8vw 0 3vw 0;
        font-size: 5vw;
    }
    .withdrawal-money-wrapper img{
        margin: 0 2vw;
        height: 6vw;
    }
    .withdrawal-button-wrapper{
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .withdrawal-button-wrapper p{
        text-align: center;
        font-size: 8vw;
        font-weight: 500;
        color: #ffffff;
    }
    .withdrawal-button-wrapper div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 8vw;
        width: 18vw;
        border-radius: 2vw;
        margin: 0 4vw;
        background-color: #FFFFff;
    }
    .withdrawal-button-wrapper div p{
        color: #333;
        font-size: 3.8vw;
    }
    .withdrawal-button-wrapper div img{
        margin:0 0 0 2vw ;
        height: 3vw;
        width: 2vw;
    }
    .withdrawal-bottom-wrapper{
        width:92vw;
        height: 20vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/distributor/佣金明细背景2v2.png") no-repeat;
        background-size: 100% 100%;
        margin: -15vw 4vw 2vw 4vw;
        border-radius:2vw;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .withdrawal-money-text-wrapper p{
        width: 40vw;
        text-align: center;
        font-size: 3.8vw;
        color: #FFFFff;
        margin: 1vw 0;
    }
    .withdrawal-money-text-wrapper p:nth-of-type(2){
        font-size: 4.8vw;
        color: #ffffff;
    }
    .list-wrapper{
        margin-top: 65vw;
    }
    .list-item-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3vw 5vw;
        font-size: 3.2vw;
        color: #999;
    }
    .list-item-name-wrapper{
        display: flex;
        align-items: center;
        padding: 1vw 0;
    }
    .list-item-des {
        width: 60vw;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:3;
    }
    .list-item-name-wrapper p:nth-of-type(1){
        font-size: 3.8vw;
        font-weight: 900;
        color: #333;
        margin-right: 4vw;
    }
    .list-item-name-label{
        padding: 0.5vw 2vw;
        color: white;
        background-color: #E9C56D;
        border-radius: 1vw;
    }
    .list-item-money p{
        text-align: center;
    }
    .list-item-money p:nth-of-type(1){
        color: #E5C988;
        font-weight: 700;
        font-size: 4.8vw;
    }
    .draw-wrapper{
        padding-top: 50vw;
    }
    .draw-body-wrapper{
        width: 88vw;
        height: 38vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/distributor/bg090201.png") no-repeat;
        background-size: 100% 100%;
        margin: 2vw 6vw;
        border-radius: 5vw;
    }
    .draw-input-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 7vw 6vw 3vw 6vw;
    }
    .draw-input-wrapper input{
        width:38vw ;
        height: 9vw;
        border-radius: 2vw;
        border: none;
        text-indent: 3vw;
        font-size: 3.6vw;
        color: #333;
    }
    input::placeholder{
        text-indent: 3vw;
        font-size: 3.6vw;
        color: #999;
    }
    .draw-input-wrapper p{
        width:24vw ;
        height: 8vw;
        border-radius: 8vw;
        line-height: 8vw;
        text-align: center;
        font-size: 3.6vw;
        background-color: #ff4c4b;
        color: #FFFFff;
    }
    .draw-tip{
        text-align: center;
        font-size: 2.8vw;
        color: #666;
        margin-top: -1vw;
    }
    .draw-button{
        width:30vw ;
        height: 8vw;
        border-radius: 8vw;
        line-height: 8vw;
        text-align: center;
        font-size: 3.6vw;
        background-color: #ff4c4b;
        color: #FFFFff;
        margin: 3vw 29vw;
    }
    .draw-close{
        width: 3.5vw;
        height: 3.5vw;
        position: absolute;
        margin: 4.5vw 85vw;
    }
</style>
